<template>
	<div v-if="loadComponent">
		<div class="row-resize pl-3 pr-2">
			<b-row >
				<!-- Product Text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.list.product") }}</span>
				</b-col>
        <!-- Storage text-->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.list.warehouse") }}</span>
				</b-col>
			</b-row>

			<b-row>
        <!-- Product Input-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
						<ProductSelect/>
          </div>
        </b-col>
        <!-- Storage input -->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <div class="inputs-params">
						<WarehouseSelect/>
          </div>
        </b-col>
      </b-row>

			<b-row >
				<!-- Quantity Text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.list.quantity") }}</span>
				</b-col>
        <!-- Unit text-->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.list.unit") }}</span>
				</b-col>
			</b-row>

			<b-row>
        <!-- Quantity Input-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
						<EditQuantity/>
          </div>
        </b-col>
        <!-- Unit input -->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <div class="inputs-params">
						<EditUnit/>
          </div>
        </b-col>
      </b-row>
		</div>
			
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import ProductSelect from "@/components/harvestStorage/edit/ProductSelect.vue";
import WarehouseSelect from "@/components/harvestStorage/edit/WarehouseSelect.vue";
import EditQuantity from "@/components/harvestStorage/edit/EditQuantity.vue";
import EditUnit from "@/components/harvestStorage/edit/EditUnit.vue";
export default {
	mixins: [DetailUpdateMixin],
	data() {
    return {
      loadComponent: false,
    };
  },
	components: { 
		ProductSelect,
		WarehouseSelect,
		EditQuantity,
		EditUnit
  },
	computed: {
    ...mapGetters({
      currentEditedHarvest: "harvestStorage/editedEntry",
			harvests: "harvestStorage/harvests",
      user: "user/user",
    }),
		
  },
	
	methods: {
		...mapActions({
			getHarvestsByActivity: "harvestStorage/getHarvestsStorage",
			getHarvest: "harvestStorage/getHarvest"
		}),
		...mapMutations({
			resetEditedHarvest: "harvestStorage/resetEditedEntry"
		})
	},

	async mounted() {
		this.loadComponent = false;
		// Réinitialise les paramètres édités de la récolte courante
		await this.resetEditedHarvest();
		// Lorsqu'on modifie une activité de transport, on récupère les récoltes qui y sont associées
		if(Object.keys(this.currentEntry).length !== 0){
			await this.getHarvestsByActivity({idActivity: this.currentEntry.id});
			if(this.harvests.length > 0) {
				await this.getHarvest({id: this.harvests[0].id})
			}
		}
		this.loadComponent = true;
	}
}
</script>
<style lang="scss" scoped>

</style>