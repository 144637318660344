<template>
<!-- Ce composant est utilisé dans l'édition des récoltes et des activités de transport -->
  <div>
    <!-- Quantité -->
    <h4 v-if="isOnHarvest" class="subtitle-form">{{ $t("harvestStorage.list.quantity") }}</h4>
    <b-row class="mb-2">
      <b-col>
        <NumericInput
          id="quantity"
          inputRef="input"
          :value="parseFloat(quantity)"
          @input="i => {quantity = i}"
          :required="isOnHarvest"
          :numberOfDigits="0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      quantity: "",
      loadComponent: false,
      isOnHarvest: null
    };
  },
  async mounted() {
    this.loadComponent = false;
    // En mode modification, on récupère la quantité courante
    this.isOnHarvest = this.$route.name.includes('harvestStorage');
    if (this.$route.name === "harvestStorage.edit") {
      this.quantity = this.currentEntry.quantity;
    }
    if (this.$route.name === "activity.edit" && this.harvests.length > 0) {
      this.quantity = this.harvests[0].quantity;
    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  computed: {
    ...mapGetters({
      currentHarvest: "harvestStorage/currentEntry",
      harvests: "harvestStorage/harvests",
    }),
  },
  watch: {
    quantity: {
      handler: async function () {
        await this.$store.dispatch("harvestStorage/setEditedEntryValue", { quantity: this.quantity != "" ? parseInt(this.quantity, 10) : undefined });
      },
    }
  },
  components: {
    NumericInput
  }
};
</script>

<style></style>
